// import { gsap, CSSPlugin, Power1 } from "gsap/all";

export default class Loader {

  constructor() {
    this.body = document.querySelector('body');

    // if (!this.body.classList.contains('is-home')) {
    //   return;
    // }

    this.heroItems = document.querySelectorAll('.hero .opacity-0');

    if (!this.heroItems.length) {
      return;
    }

    this.lights = document.querySelectorAll('.lights-light.opacity-0');

    setTimeout(() => {
      this.body.classList.remove('opacity-0');
    }, 400);

    setTimeout(() => {
      this.lights.forEach((element, index) => {
        element.classList.remove('opacity-0');
      });
    }, 600);

    setTimeout(() => {
      this.heroItems.forEach((element, index) => {
        element.style.transitionDelay = index * 0.4 + 's';
        element.classList.remove('opacity-0', 'translate-y-8');
      });
    }, 1200);
  }
}
