import { gsap, ScrollTrigger, Power4 } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

export default class Lights {

  constructor() {
    this.body = document.querySelector('body');

    // const lights = document.querySelectorAll('.lights-light');
    // for (let i = 0; i < lights.length; i++) {
    //   lights[i].style.animationDelay = this.randomInt(1000, 5000) / 1000 + 's';
    // }




    const layers = document.querySelectorAll('.lights-layer');
    if (!layers.length) {
      return;
    }

    for (let i = 0; i < layers.length; i++) {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: this.body,
          start: "top top",
          end: "bottom bottom",
          scrub: true,
          ease: Power4.easeOut,
        }
      });

      tl.to(layers[i], {
        translateX: '1px',
        translateY: layers[i].dataset.transformTarget,
        translateZ: '1px',
        duration: 1,
      });
    }

  }


  randomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
}
