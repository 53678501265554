export default class Video {

  constructor(selector) {
    this.wrappers = document.querySelectorAll(selector);
    if (!this.wrappers.length) {
      return;
    }

    for (let index = 0; index < this.wrappers.length; index++) {
      const wrapper = this.wrappers[index];
      const playTrigger = wrapper.querySelector('.video--play-trigger');
      const playTriggerLabel = wrapper.querySelector('.video--play-trigger-label');


      this.videoLandscape = wrapper.querySelector('.video--landscape');
      this.videoPortrait = wrapper.querySelector('.video--portrait');
      this.video = null;
      window.addEventListener('resize', (event) => {
        this.setVideo();
      });
      this.setVideo();


      if (playTrigger) {
        playTrigger.addEventListener('click', (event) => {
          event.preventDefault();
          if (this.video.paused) {
            this.video.play();
          }
          else {
            this.video.pause();
          }
          // loop.pause();
        });
      }


      if (wrapper) {
        wrapper.addEventListener('play', (event) => {
          if (event.target == this.video) {
            setTimeout(() => {
              wrapper.classList.add('is-playing');
              if (playTriggerLabel) {
                playTriggerLabel.textContent = 'Stop';
              }
            }, 96);
          }
        }, true);

        wrapper.addEventListener('pause', (event) => {
          if (event.target == this.video) {
            setTimeout(() => {
              wrapper.classList.remove('is-playing');
              if (playTriggerLabel) {
                playTriggerLabel.textContent = 'Play';
              }
            }, 96);
          }
        }, true);

        wrapper.addEventListener('ended', (event) => {
          if (event.target == this.video) {
            this.video.currentTime = 0;
            this.video.load();
            // loop.play();

            setTimeout(() => {
              wrapper.classList.remove('is-playing');
              if (playTriggerLabel) {
                playTriggerLabel.textContent = 'Play';
              }
            }, 96);
          }
        }, true);
      }

      // if (loop) {
      //   loop.play();
      // }

    }
  }



  setVideo() {
    this.videoLandscape.pause();
    if (this.videoPortrait) {
      this.videoPortrait.pause();
    }
    if (this.videoLandscape.offsetParent !== null) {
      this.video = this.videoLandscape;
    }
    if (this.videoPortrait && this.videoPortrait.offsetParent !== null) {
      this.video = this.videoPortrait;
    }
  }


}
