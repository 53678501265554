export default class NavMobile {

  constructor() {
    this.html = document.querySelector('html');
    this.body = document.querySelector('body');

    if (this.body.dataset.navMobileInitialized) {
      return;
    }

    this.body.dataset.navMobileInitialized = true;
    this.triggerButton = document.querySelector('.navigation--trigger');
    this.trigger = document.querySelector('.navigation--trigger-overlay');
    this.main = document.querySelector('.base--main');
    this.footer = document.querySelector('.base--footer');
    this.navigationWrapper = document.querySelector('.header--navigation-wrapper');
    this.active = false;

    if (this.trigger) {
      this.body.addEventListener('click', (event) => {
        if (this.active) {
          setTimeout(() => {
            this.html.classList.remove('navigation__active');
            this.body.classList.add('overflow-x-hidden');
            this.main.classList.replace('opacity-0', 'opacity-100');
            this.footer.classList.replace('opacity-0', 'opacity-100');
            this.navigationWrapper.classList.replace('opacity-100', 'opacity-0');
            this.navigationWrapper.classList.replace('visible', 'invisible');
          }, 300)
          this.active = false;
        }
        else {
          let triggerHit = false
          if (event.target == this.trigger) {
            triggerHit = true;
          }

          if (triggerHit) {
            this.active = true;
            this.html.classList.add('navigation__active');
            this.body.classList.remove('overflow-x-hidden');
            this.main.classList.replace('opacity-100', 'opacity-0');
            this.footer.classList.replace('opacity-100', 'opacity-0');
            this.navigationWrapper.classList.replace('opacity-0', 'opacity-100');
            this.navigationWrapper.classList.replace('invisible', 'visible');
          }
        }

        this.triggerButton.setAttribute('aria-pressed', this.active);
        this.triggerButton.setAttribute('aria-label', this.active ? 'Navigation ausblenden' : 'Navigation einblenden');
        this.navigationWrapper.setAttribute('aria-hidden', !this.active);
      });

    }
  }

}
