import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

export default class Reveal {

  constructor(selector) {
    const wrappers = document.querySelectorAll(selector);
    if (!wrappers.length) {
      return;
    }

    for (let i = 0; i < wrappers.length; i++) {
      const items = wrappers[i].querySelectorAll('.reveal');
      for (let j = 0; j < items.length; j++) {
        ScrollTrigger.create({
          trigger: items[j],
          start: "top bottom",
          end: "bottom top",
          onEnter: (self) => {
            setTimeout(() => {
              items[j].classList.add('reveal__show')
            }, Math.min(j * 80, 2000));
          },
          onEnterBack: (self) => {
            setTimeout(() => {
              items[j].classList.add('reveal__show')
            }, Math.min(j * 80, 2000));
          },
        });
      }
    }

  }

}
